<template>
  <div>
    <ly-searchList :iptData="iptData" @searchAdd="searchAdd" @resetBtn="resetBtn">
    </ly-searchList>
    <div class="m-t1 tableBox">
    <ly-table :columns="columns" ref="tableRef" size="small" :data="data" rowKey="id" :pagination="pagination" @pageChange="pageChange" :loading="loading">
      <template #idx="{ index }">
        {{  index+1 }}
      </template>
      <template #add="{ record }">
        <a @click="edit(record)">编辑</a>
      </template>
    </ly-table>
  </div>
    <ly-modal v-model:visible="visible" title="编辑" :footer="false">
      <ly-formList ref="formListRef" :fromList="fromList" @getVal="getVal" @cancel="visible = false"></ly-formList>
    </ly-modal>
  </div>
</template>

<script setup>
import { onMounted, ref } from 'vue'
import { iptData, columns, fromList } from './line'
import { carrierLinePage, carrierLineLook, carrierLineEdit } from '@/api/transport/vehicle'
import { message } from 'ant-design-vue';

const msgInfo = ref({})
const loading = ref(false)
const visible = ref(false)
const searchMsg = ref({})
const data = ref([])
const formListRef = ref(null)
const pagination = ref({
  current: 1,
  pageSize: 10,
  showSizeChanger: true,
  showQuickJumper: true,
  showTotal: (total, range) => `共 ${total} 条`
})
const resetBtn = () => {
  searchMsg.value = {}
  pagination.value.current = 1
  reqCarrierLinePage()
}
const searchAdd = (e) =>{
  let objStart = {}
  let objEnd = {}
  if(e.start?.length > 0){
    objStart = {
      startProvince:e.start[0],
      startCity:e.start[1],
      startArea:e.start[2]
    }
  }
  if(e.end?.length > 0){
    objEnd = {
      endProvince:e.end[0],
      endCity:e.end[1],
      endArea:e.end[2]
    }
  }
 searchMsg.value = Object.assign(objStart,objEnd)
 pagination.value.current = 1
 reqCarrierLinePage()
}
//分页事件
const pageChange = (e) => {
  pagination.value = e
  reqCarrierLinePage()
}
const edit = (record) => {
  msgInfo.value = record
  carrierLineLook(record.id).then(res => {
    if (res.code !== 10000) return
    visible.value = true
    fromList.value.forEach(item => {
      item.value = res.data[item.prop]
    })
  })

}
const getVal = (e) => {
  formListRef.value.fileLoading = true
  e.carrierId = msgInfo.value.carrierId
  e.id = msgInfo.value.id
  carrierLineEdit(e).then(res => {
    if(res.code !== 10000) return
    visible.value = false
    message.success(res.msg)
    reqCarrierLinePage()
  }).finally(() => {
    if(formListRef.value){
      formListRef.value.fileLoading = false
    }
  })

}

const reqCarrierLinePage = () => {
  loading.value = true
  let msg = {
    current: pagination.value.current,
    size: pagination.value.pageSize
  }
  Object.assign(msg, searchMsg.value)
  carrierLinePage(msg).then(res => {
    if (res.code !== 10000) return
    data.value = res.data.records
    pagination.value.total = res.data.total
    console.log(res)
  }).finally(() => {
    loading.value = false
  })
}
onMounted(() => {
  reqCarrierLinePage()
})
</script>

<style lang="less" scoped>
.tableBox {
  padding: 15px;
  background: #ffffff;
  min-height: calc(100vh - 230px);
}
</style>     