import { ref } from 'vue'
export const iptData = ref([
  {
    type: 'address',
    placeholder: '线路起点',
    value: null,
    prop: 'start',
    width: '200px',
  },
  {
    type: 'address',
    placeholder: '线路终点',
    value: null,
    prop: 'end',
    width: '200px'
  }
])
export const columns = ref([
  {
    title: '序号',
    dataIndex: 'idx',
    slots: { customRender: 'idx' },
    width: '100px',
    align: 'center',
  },
  {
    title: '线路名称',
    dataIndex: 'lineName',
    align: 'center',
  },
  {
    title: '报价',
    dataIndex: 'cost',
    align: 'center',
  },
  {
    title: '操作',
    dataIndex: 'add',
    slots: { customRender: 'add' },
    align: 'center',
  },
])
export const fromList = ref([
  {
    label: '线路名称',
    name: 'lineName',
    type: 'input',
    placeholder: '',
    value: null,
    prop: 'lineName',
    disabled: true,
    width: '100%',
  },
  {
    label: '报价',
    name: 'cost',
    type: 'inputNumber',
    placeholder: '',
    value: null,
    prop: 'cost',
    width: '100%',
  }
])